import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Solutions from "/src/components/pages/solutions/Solutions";
import Benefits from "/src/components/pages/products/Benefits";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit01.json";

const ADSecurity = ({data}) => {

    const {ad} = data

    return (
        <Layout lang={ad.lang} seo={ad.SEO}>
            <LottieAnimation animationData={circuit2} turned="true"/>
            <Solutions
                title={ad.title}
                subTitle={ad.challenges.title}
                challangesCards={ad.challenges.solution_card}
                subTitle2={ad.solutions.title}
                solutionCards={ad.solutions.solution_card}
                button={ad.solutions_button}
                buttonStyle="one-identity-primary"
            />
            <Benefits
                small_title={ad.benefits_title}
                benefits={ad.benefits_card}
                button={ad.benefits_button}
                button_style="one-identity-primary"
                customPadding="noTop"
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleAdSecurity($locale: String) {
         ad: strapiAdSecurities(lang: { eq: $locale }) {
          lang
          title
          challenges {
            id
            title
            solution_card {
              avatar {
                url
              }
              title
              description
              id
            }
          }
          solutions {
            id
            title
            solution_card {
              avatar {
                url
              }
              title
              description
              id
            }
          }
          solutions_button {
            id
            link
            name
          }
          benefits_title
          benefits_card {
            id
            title
          }
          benefits_button {
            id
            link
            name
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default ADSecurity